import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import { ConfigProvider } from 'antd'
import ru from 'antd/es/locale/ru_RU'
import 'antd/dist/antd.css'
import 'moment/locale/ru'

import * as serviceWorker from './serviceWorker'
import withLayout from './components/Layout'
import { Provider } from './context'

import Main from './containers/main'
import News from './containers/news'
import NewsAdd from './containers/news/add'
import NewsEdit from './containers/news/edit'
import NewsSingle from './containers/news/single'
import Portfolio from './containers/portfolio'
import PortfolioAdd from './containers/portfolio/add'
import PortfolioEdit from './containers/portfolio/edit'
import PortfolioSingle from './containers/portfolio/single'
import Login from './containers/login'

const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        background: whitesmoke;
    }

    .center {
        width: 100%;
        height: 60vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ant-layout-sider {
        z-index: 1;
    }

    figure {
        max-width: 500px;
    }
`

const App = () => {
    return (
        <Provider>
            <ConfigProvider locale={ru}>
                <Router>
                    <Switch>
                        <Route path="/" exact component={props => withLayout(props, Main)} />
                        <Route path="/news" exact component={props => withLayout(props, News)} />
                        <Route
                            path="/news/add"
                            exact
                            component={props => withLayout(props, NewsAdd)}
                        />
                        <Route
                            path="/news/:id/edit"
                            exact
                            component={props => withLayout(props, NewsEdit)}
                        />
                        <Route
                            path="/news/:id"
                            exact
                            component={props => withLayout(props, NewsSingle)}
                        />
                        <Route
                            path="/portfolio"
                            exact
                            component={props => withLayout(props, Portfolio)}
                        />
                        <Route
                            path="/portfolio/add"
                            exact
                            component={props => withLayout(props, PortfolioAdd)}
                        />
                        <Route
                            path="/portfolio/:id/edit"
                            exact
                            component={props => withLayout(props, PortfolioEdit)}
                        />
                        <Route
                            path="/portfolio/:id"
                            exact
                            component={props => withLayout(props, PortfolioSingle)}
                        />
                        <Route path="/login" exact component={Login} />
                    </Switch>
                </Router>
                <GlobalStyles />
            </ConfigProvider>
        </Provider>
    )
}

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()
