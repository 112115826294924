import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import { SnippetsOutlined, DatabaseOutlined } from '@ant-design/icons'

const MenuComponent = () => {
    const { pathname } = window.location
    return (
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[pathname]}>
            <Menu.Item style={{ marginTop: 7 }} key="/news">
                <MenuLink to="/news">
                    <SnippetsOutlined />
                    Новости
                </MenuLink>
            </Menu.Item>
            <Menu.Item key="/portfolio">
                <MenuLink to="/portfolio">
                    <DatabaseOutlined />
                    Портфолио
                </MenuLink>
            </Menu.Item>
        </Menu>
    )
}

const MenuLink = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
        color: white;
    }
`

export default MenuComponent
