import React from 'react'

import Top from '../components/Top'

const Main = () => {
    return (
        <>
            <Top title="Главная" helpText="Это панель администратора IT Kitchen" />
        </>
    )
}

export default Main
