import React, { useState, useEffect } from 'react'
import { message, Spin, Button, Popconfirm, Empty } from 'antd'
import styled from 'styled-components'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-react'

import Top from '../../components/Top'
import api from '../../utils/api'

const PortfolioInfo = ({ portfolio, history }) => {
    const [deleting, setDeleting] = useState(false)

    const handleDelete = () => {
        setDeleting(true)
        api()
            .delete(`/portfolio/${portfolio._id}`)
            .then(() => {
                message.success('Портфолио удалена')
                setDeleting(false)
                history.replace('/portfolio')
            })
            .catch(err => {
                console.error(err)
                message.error('Не удалось удалить портфолио')
                setDeleting(false)
            })
    }

    return (
        <PortfolioView>
            <div className="namevalue">
                <div className="name">Название</div>
                <div className="value">{portfolio.title}</div>
            </div>
            <div className="namevalue">
                <div className="name">Ссылки</div>
                <div className="value">{portfolio.urls}</div>
            </div>
            <div className="namevalue">
                <div className="name">Текст</div>
                <div className="value ck-view">
                    <CKEditor editor={ClassicEditor} data={portfolio.description} disabled />
                </div>
            </div>
            <div className="namevalue">
                <div className="name">Изображение</div>
                {portfolio.image ? (
                    <div className="images">
                        <img alt={portfolio.image} src={`/uploads/${portfolio.image}`} />
                    </div>
                ) : (
                    <div className="value">Нет изображения</div>
                )}
            </div>
            <div className="actions">
                <Button
                    onClick={() => history.push(`/portfolio/${portfolio._id}/edit`)}
                    type="primary"
                    ghost
                >
                    Изменить
                </Button>
                <Popconfirm title="Удалить?" onConfirm={handleDelete}>
                    <Button loading={deleting} type="danger" ghost>
                        Удалить
                    </Button>
                </Popconfirm>
            </div>
        </PortfolioView>
    )
}

const PortfolioSingle = ({ match, history }) => {
    const [portfolio, setPortfolio] = useState(null)
    const [loading, setLoading] = useState(true)
    const { id } = match.params

    const getSinglePortfolio = () => {
        setLoading(true)
        api()
            .get(`/portfolio/${id}`)
            .then(({ data }) => {
                setPortfolio(data.portfolio)
                setLoading(false)
            })
            .catch(err => {
                console.error(err)
                message.error('Не удалось загрузить портфолио')
                setLoading(false)
            })
    }

    useEffect(() => {
        getSinglePortfolio()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Top title="Информация о Портфолио" />
            <Content>
                {loading ? (
                    <div className="center">
                        <Spin />
                    </div>
                ) : portfolio ? (
                    <PortfolioInfo portfolio={portfolio} history={history} />
                ) : (
                    <div className="center">
                        <Empty />
                    </div>
                )}
            </Content>
        </>
    )
}

const Content = styled.div`
    padding: 12px 0;
`

const PortfolioView = styled.div`
    .actions {
        button {
            &:first-child {
                margin-right: 10px;
            }
        }
    }

    .ck-editor__top {
        display: none;
    }

    .ck-editor__main {
        margin-top: 6px;
    }

    .namevalue {
        font-size: 14px;
        margin-bottom: 12px;

        .name {
            font-weight: 300;
        }

        .value {
            font-weight: 500;
            margin-top: 3px;
            white-space: pre-line;
        }

        .ck-view {
            font-weight: unset;
        }

        .images {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 10px;
            margin-bottom: 20px;

            img {
                height: 140px;
                margin-right: 10px;

                @media only screen and (max-width: 400px) {
                    width: 100%;
                    height: auto;
                    margin-right: 0;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
`

export default PortfolioSingle
