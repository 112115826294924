import React, { useState, useEffect } from 'react'
import { message, Form as AntForm, Input, Button, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-react'
import '@ckeditor/ckeditor5-build-classic/build/translations/ru'

import Top from '../../components/Top'
import api from '../../utils/api'

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const editorToolbar = [
    'heading',
    '|',
    'bold',
    'italic',
    'link',
    'bulletedList',
    'numberedList',
    '|',
    'undo',
    'redo'
]

const UploadButton = () => (
    <UploadButtonView>
        <PlusOutlined />
        <div className="text">Загрузить</div>
    </UploadButtonView>
)

const PortfolioEdit = ({ match, history }) => {
    const [loading, setLoading] = useState(true)
    const [editing, setEditing] = useState(false)
    const [deletedImage, setDeletedImage] = useState(null)
    const { id } = match.params
    const [form] = Form.useForm()

    const getSinglePortfolio = () => {
        api()
            .get(`/portfolio/${id}`)
            .then(({ data }) => {
                setLoading(false)
                let image = []
                if (data.portfolio.image) {
                    image = [
                        {
                            uid: '-1',
                            name: data.portfolio.image,
                            status: 'done',
                            url: `/uploads/${data.portfolio.image}`,
                            uploaded: true
                        }
                    ]
                }
                form.setFieldsValue({
                    title: data.portfolio.title,
                    description: data.portfolio.description,
                    urls: data.portfolio.urls,
                    image
                })
            })
            .catch(err => {
                console.error(err)
                message.error('Не удалось загрузить портфолио')
            })
    }

    const onSubmitForm = values => {
        const images = values.image.map(img => (img.originFileObj ? img.originFileObj : img))
        if (images.length > 1) {
            message.warning('Вы не можете загрузить несколько изображений')
        } else {
            const image = images[0]
            const formData = new FormData()
            formData.append('title', values.title)
            formData.append('description', values.description)
            formData.append('urls', values.urls)
            formData.append('image', image)
            if (deletedImage) {
                formData.append('deletedImage', deletedImage)
            }
            setEditing(true)
            api()
                .put(`/portfolio/${id}`, formData)
                .then(() => {
                    setEditing(false)
                    message.success('Портфолио изменена')
                    history.push(`/portfolio/${id}`)
                })
                .catch(err => {
                    console.error(err)
                    message.error('Не удалось изменить портфолио')
                    setEditing(false)
                })
        }
    }

    const normFile = e => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    useEffect(() => {
        getSinglePortfolio()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Top title="Изменить портфолио" />
            <Form form={form} onFinish={onSubmitForm} layout="vertical" name="edit-portfolio">
                <Form.Item label="Название" name="title" rules={[rules.required]}>
                    <Input disabled={loading} />
                </Form.Item>
                <Form.Item
                    label="Текст"
                    name="description"
                    valuePropName="data"
                    rules={[rules.required]}
                    getValueFromEvent={(_, editor) => {
                        const data = editor.getData()
                        return data
                    }}
                >
                    <CKEditor
                        disabled={loading}
                        editor={ClassicEditor}
                        config={{
                            toolbar: editorToolbar,
                            language: 'ru'
                        }}
                        language="ru"
                    />
                </Form.Item>
                <Form.Item label="Ссылки" name="urls">
                    <Input.TextArea disabled={loading} />
                </Form.Item>
                <Form.Item
                    name="image"
                    label="Изображение (Логотип)"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    style={{ flexFlow: 'column' }}
                >
                    <Upload
                        accept="image/*"
                        disabled={loading}
                        multiple={false}
                        beforeUpload={() => false}
                        listType="picture-card"
                        onRemove={removed => {
                            if (removed.uploaded) {
                                setDeletedImage(removed.name)
                            }
                        }}
                    >
                        <UploadButton />
                    </Upload>
                </Form.Item>
                <Button disabled={loading} loading={editing} type="primary" htmlType="submit">
                    Изменить
                </Button>
            </Form>
        </>
    )
}

const Form = styled(AntForm)`
    max-width: 600px;
    margin-top: 7px;

    .ck-editor {
        border-radius: 2px;
        border: 1px solid #d9d9d9;
        transition: 0.3s border-color;

        &:hover {
            border-color: #40a9ff;
        }
    }

    .ck.ck-toolbar {
        border: none;
        border-bottom: 1px solid #d9d9d9 !important;
    }

    .ck-focused {
        border: none !important;
        box-shadow: none !important;
    }

    .ck.ck-editor__editable_inline {
        border: none;
    }

    .ant-form-item-has-error {
        .ck-editor {
            border: 1px solid #ff4d4f;
        }
    }
`

const UploadButtonView = styled.div`
    .text {
        margin-top: 8px;
        color: #666;
    }
`

export default PortfolioEdit
