import React, { useEffect, useContext } from 'react'
import styled from 'styled-components'
import { Spin, Layout as AntLayout } from 'antd'

import api from '../utils/api'
import { Store } from '../context'
import { SET_ADMIN } from '../context/admin'
import Header from './Header'
import Menu from './Menu'

const { Content: AntContent, Footer: AntFooter, Sider: AntSider } = AntLayout

const Layout = ({ children, ...props }) => {
    const { adminStore } = useContext(Store)
    const [state, dispatch] = adminStore

    useEffect(() => {
        getAdmin()
        // eslint-disable-next-line
    }, [])

    const getAdmin = () =>
        api()
            .get('/admin')
            .then(({ data }) => {
                dispatch({
                    type: SET_ADMIN,
                    payload: data
                })
            })
            .catch(err => {
                console.error(err)
                props.history.push('/login')
            })

    if (state.loading) {
        return (
            <LoadingView>
                <Spin />
            </LoadingView>
        )
    }

    return (
        <Provider>
            <Sider breakpoint="lg" collapsedWidth="0">
                <div onClick={() => window.open('/', '_self')} className="logo">
                    <img src="/logo.png" alt="IT Kitchen" />
                </div>
                <Menu />
            </Sider>
            <AntLayout>
                <Header {...props} />
                <Content>
                    <div className="inside">{children}</div>
                </Content>
                <Footer>Панель администратора IT Kitchen @2020</Footer>
            </AntLayout>
        </Provider>
    )
}

const withLayout = (props, Component) => (
    <Layout {...props}>
        <Component {...props} />
    </Layout>
)

const LoadingView = styled.div`
    background: whitesmoke;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Content = styled(AntContent)`
    margin: 24px 16px 0;

    @media only screen and (max-width: 480px) {
        margin: 10px 0;
    }

    .inside {
        padding: 24px;
        background: #fff;
        min-height: 100%;

        @media only screen and (max-width: 480px) {
            padding: 14px;
        }
    }
`

const Footer = styled(AntFooter)`
    text-align: center;
`

const Provider = styled(AntLayout)`
    min-height: 100vh;

    .logo {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: whitesmoke;
        cursor: pointer;
        transition: 0.3s background;

        &:hover {
            background: #aed7ff;
        }

        img {
            width: 60%;
        }
    }

    .ant-layout-sider-zero-width-trigger {
        top: 11px;
    }
`

const Sider = styled(AntSider)`
    @media only screen and (max-width: 992px) {
        position: absolute;
        height: 100%;
    }
`

export default withLayout
