import React, { useContext, useEffect } from 'react'
import { Spin, Button, Empty } from 'antd'
import styled from 'styled-components'
import moment from 'moment'

import { Store } from '../../context'
import { SET_NEWS } from '../../context/news'
import Top from '../../components/Top'
import api from '../../utils/api'

const News = ({ history }) => {
    const { newsStore } = useContext(Store)
    const [state, dispatch] = newsStore

    const { loading } = state

    const getNews = () => {
        api()
            .get('/news')
            .then(({ data }) => {
                dispatch({
                    type: SET_NEWS,
                    payload: data.news
                })
            })
            .catch(err => {
                console.error(err)
                dispatch({
                    type: SET_NEWS,
                    payload: []
                })
            })
    }

    useEffect(() => {
        getNews()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Top
                title="Новости"
                action={
                    <Button onClick={() => history.push('/news/add')} type="primary" ghost>
                        + Добавить
                    </Button>
                }
            />
            <Content>
                {loading ? (
                    <div className="center">
                        <Spin />
                    </div>
                ) : state.news.length > 0 ? (
                    <NewsView>
                        {state.news.map(item => (
                            <NewsItem
                                key={item._id}
                                onClick={() => history.push(`/news/${item._id}`)}
                            >
                                <div className="title">{item.title}</div>
                                <div className="short">{item.short}</div>
                                <div className="created">{moment(item.created).format('lll')}</div>
                            </NewsItem>
                        ))}
                    </NewsView>
                ) : (
                    <div className="center">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                )}
            </Content>
        </>
    )
}

const NewsView = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;

    @media only screen and (max-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media only screen and (max-width: 800px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 360px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const NewsItem = styled.div`
    border: 1px solid silver;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;

    &:hover {
        border: 1px solid gray;
    }

    .title {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }

    .short {
        font-size: 12px;
        font-weight: 300;
        color: black;
        margin-top: 7px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }

    .created {
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }
`

const Content = styled.div`
    padding: 15px 0;
`

export default News
