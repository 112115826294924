export const SET_NEWS = 'news/SET_NEWS'

export const initialValue = { news: [], loading: true }

const reducer = (state, action) => {
    switch (action.type) {
        case SET_NEWS:
            return {
                ...state,
                news: action.payload,
                loading: false
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer
