import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Card as AntCard, Input, Button as AntButton, message } from 'antd'

import api from '../utils/api'

const requiredRule = {
    required: true,
    message: 'Обязательное поле'
}

const Login = ({ history }) => {
    const [loading, setLoading] = useState(false)

    const handleSubmitForm = values => {
        setLoading(true)
        api()
            .post('/admin/login', values)
            .then(({ data }) => {
                message.success('Вход успешно выполнен')
                localStorage.setItem('token', data.token)
                history.push('/')
                setLoading(false)
            })
            .catch(err => {
                message.error('Неправильный логин или пароль')
                console.error(err)
                setLoading(false)
            })
    }

    return (
        <Wrapper>
            <Logo src={`/logo.png`} />
            <Card>
                <Form
                    onFinish={handleSubmitForm}
                    layout="vertical"
                    name="login"
                    initialValues={{
                        remember: true
                    }}
                >
                    <Form.Item colon={false} label="Логин" name="login" rules={[requiredRule]}>
                        <Input placeholder="Введите логин..." />
                    </Form.Item>
                    <Form.Item label="Пароль" name="password" rules={[requiredRule]}>
                        <Input.Password placeholder="Введите пароль..." />
                    </Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Войти
                    </Button>
                </Form>
            </Card>
        </Wrapper>
    )
}

const Card = styled(AntCard)`
    width: 400px;

    @media only screen and (max-width: 420px) {
        width: 95%;
    }
`

const Button = styled(AntButton)`
    margin-bottom: 10px;
    margin-top: 5px;
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;

    @media only screen and (max-width: 420px) {
        justify-content: flex-start;
        padding-top: 30px;
    }
`

const Logo = styled.img`
    height: 40px;
    margin-bottom: 22px;
`

export default Login
