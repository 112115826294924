export const SET_ADMIN = 'admin/SET_ADMIN'

export const initialValue = { admin: null, loading: true }

const reducer = (state, action) => {
    switch (action.type) {
        case SET_ADMIN:
            return {
                ...state,
                admin: action.payload,
                loading: false
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer
