import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Spin, Empty, Button } from 'antd'
import moment from 'moment'

import Top from '../../components/Top'
import { Store } from '../../context'
import { SET_PORTFOLIO } from '../../context/portfolio'
import api from '../../utils/api'

const Portfolio = ({ history }) => {
    const { portfolioStore } = useContext(Store)
    const [state, dispatch] = portfolioStore

    const getPortfolios = () => {
        api()
            .get('/portfolio')
            .then(({ data }) => {
                dispatch({
                    type: SET_PORTFOLIO,
                    payload: data.portfolios
                })
            })
            .catch(err => {
                console.error(err)
                dispatch({
                    type: SET_PORTFOLIO,
                    payload: []
                })
            })
    }

    useEffect(() => {
        getPortfolios()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Top
                title="Портфолио"
                action={
                    <Button onClick={() => history.push('/portfolio/add')} type="primary" ghost>
                        + Добавить
                    </Button>
                }
            />
            <Content>
                {state.loading ? (
                    <div className="center">
                        <Spin />
                    </div>
                ) : state.portfolios.length > 0 ? (
                    <PortfoliosView>
                        {state.portfolios.map(portfolio => (
                            <PortfolioItem
                                onClick={() => history.push(`/portfolio/${portfolio._id}`)}
                                key={portfolio._id}
                            >
                                <div className="image">
                                    <img
                                        src={
                                            portfolio.image
                                                ? `/uploads/${portfolio.image}`
                                                : `/default-logo.png`
                                        }
                                        alt={portfolio.title}
                                    />
                                </div>
                                <div className="title">{portfolio.title}</div>
                                <div className="created">
                                    {moment(portfolio.created).format('lll')}
                                </div>
                            </PortfolioItem>
                        ))}
                    </PortfoliosView>
                ) : (
                    <div className="center">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                )}
            </Content>
        </>
    )
}

const Content = styled.div`
    padding: 15px 0;
`

const PortfoliosView = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;

    @media only screen and (max-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media only screen and (max-width: 800px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 360px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const PortfolioItem = styled.div`
    border: 1px solid silver;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;

    &:hover {
        border: 1px solid gray;
    }

    .image {
        margin-bottom: 10px;
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
        }
    }

    .title {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }

    .created {
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }
`

export default Portfolio
