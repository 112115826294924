import React, { createContext, useReducer } from 'react'

import adminReducer, { initialValue as adminInitValue } from './admin'
import newsReducer, { initialValue as newsInitValue } from './news'
import portfolioReducer, { initialValue as portfolioInitValue } from './portfolio'

const Store = createContext()

const Provider = ({ children }) => {
    const adminStore = useReducer(adminReducer, adminInitValue)
    const newsStore = useReducer(newsReducer, newsInitValue)
    const portfolioStore = useReducer(portfolioReducer, portfolioInitValue)

    const value = {
        adminStore,
        newsStore,
        portfolioStore
    }

    return <Store.Provider value={value}>{children}</Store.Provider>
}

export { Store, Provider }
