import React from 'react'
import styled from 'styled-components'

const Top = ({ title, helpText, action }) => {
    return (
        <Wrapper helpText={helpText}>
            <div className="left">
                <div className="title">{title}</div>
                {helpText && <div className="help-text">{helpText}</div>}
            </div>
            {action && action}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media only screen and (max-width: 480px) {
        flex-direction: column;
    }

    .left {
        .title {
            font-size: 18px;

            ${props =>
                !props.helpText
                    ? `
                        @media only screen and (max-width: 480px) {
                            margin-bottom: 10px;
                        }
                    `
                    : ''}
        }

        .help-text {
            color: gray;
            font-size: 14px;
            margin-top: 3px;

            @media only screen and (max-width: 480px) {
                margin-bottom: 10px;
            }
        }
    }
`

export default Top
