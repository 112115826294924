import React, { useState, useEffect } from 'react'
import { message, Spin, Button, Popconfirm, Empty } from 'antd'
import styled from 'styled-components'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-react'

import Top from '../../components/Top'
import api from '../../utils/api'

const NewsInfo = ({ news, history }) => {
    const [deleting, setDeleting] = useState(false)

    const handleDelete = () => {
        setDeleting(true)
        api()
            .delete(`/news/${news._id}`)
            .then(() => {
                message.success('Новость удалена')
                setDeleting(false)
                history.replace('/news')
            })
            .catch(err => {
                console.error(err)
                message.error('Не удалось удалить новость')
                setDeleting(false)
            })
    }

    return (
        <NewsView>
            <div className="namevalue">
                <div className="name">Заголовок</div>
                <div className="value">{news.title}</div>
            </div>
            <div className="namevalue">
                <div className="name">Красткое описание</div>
                <div className="value">{news.short ? news.short : 'Нет краткого описания'}</div>
            </div>
            <div className="namevalue">
                <div className="name">Текст</div>
                <div className="value ck-view">
                    <CKEditor editor={ClassicEditor} data={news.description} disabled />
                </div>
            </div>
            <div className="namevalue">
                <div className="name">Фотографии</div>
                {news.images.length > 0 ? (
                    <div className="images">
                        {news.images.map(item => (
                            <img key={item} alt={item} src={`/uploads/${item}`} />
                        ))}
                    </div>
                ) : (
                    <div className="value">Нет изображений</div>
                )}
            </div>
            <div className="actions">
                <Button onClick={() => history.push(`/news/${news._id}/edit`)} type="primary" ghost>
                    Изменить
                </Button>
                <Popconfirm title="Удалить?" onConfirm={handleDelete}>
                    <Button loading={deleting} type="danger" ghost>
                        Удалить
                    </Button>
                </Popconfirm>
            </div>
        </NewsView>
    )
}

const NewsSingle = ({ match, history }) => {
    const [news, setNews] = useState(null)
    const [loading, setLoading] = useState(true)
    const { id } = match.params

    const getSingleNews = () => {
        setLoading(true)
        api()
            .get(`/news/${id}`)
            .then(({ data }) => {
                setNews(data.news)
                setLoading(false)
            })
            .catch(err => {
                console.error(err)
                message.error('Не удалось загрузить новость')
                setLoading(false)
            })
    }

    useEffect(() => {
        getSingleNews()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Top title="Информация о Новости" />
            <Content>
                {loading ? (
                    <div className="center">
                        <Spin />
                    </div>
                ) : news ? (
                    <NewsInfo news={news} history={history} />
                ) : (
                    <div className="center">
                        <Empty />
                    </div>
                )}
            </Content>
        </>
    )
}

const Content = styled.div`
    padding: 12px 0;
`

const NewsView = styled.div`
    .actions {
        button {
            &:first-child {
                margin-right: 10px;
            }
        }
    }

    .ck-editor__top {
        display: none;
    }

    .ck-editor__main {
        margin-top: 6px;
    }

    .namevalue {
        font-size: 14px;
        margin-bottom: 12px;

        .name {
            font-weight: 300;
        }

        .value {
            font-weight: 500;
            margin-top: 3px;
            white-space: pre-line;
        }

        .ck-view {
            font-weight: unset;
        }

        .images {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 10px;
            margin-bottom: 20px;

            img {
                height: 140px;
                margin-right: 10px;

                @media only screen and (max-width: 400px) {
                    width: 100%;
                    height: auto;
                    margin-right: 0;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
`

export default NewsSingle
