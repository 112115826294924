export const SET_PORTFOLIO = 'news/SET_PORTFOLIO'

export const initialValue = { portfolios: [], loading: true }

const reducer = (state, action) => {
    switch (action.type) {
        case SET_PORTFOLIO:
            return {
                ...state,
                portfolios: action.payload,
                loading: false
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer
