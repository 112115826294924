import axios from 'axios'

const api = () => {
    const accessToken = localStorage.getItem('token')

    const instance = axios.create({
        baseURL: '/api',
        headers: { authorization: accessToken }
    })
    return instance
}

export default api
