import React, { useState, useEffect } from 'react'
import { message, Form as AntForm, Input, Button, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-react'
import '@ckeditor/ckeditor5-build-classic/build/translations/ru'

import Top from '../../components/Top'
import api from '../../utils/api'

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const editorToolbar = [
    'heading',
    '|',
    'bold',
    'italic',
    'link',
    'bulletedList',
    'numberedList',
    '|',
    'undo',
    'redo'
]

const UploadButton = () => (
    <UploadButtonView>
        <PlusOutlined />
        <div className="text">Загрузить</div>
    </UploadButtonView>
)

const NewsEdit = ({ match, history }) => {
    const [loading, setLoading] = useState(true)
    const [editing, setEditing] = useState(false)
    const [deletedImages, setDeletedImages] = useState([])
    const { id } = match.params
    const [form] = Form.useForm()

    const getSingleNews = () => {
        api()
            .get(`/news/${id}`)
            .then(({ data }) => {
                setLoading(false)
                const images = data.news.images.map(img => {
                    return {
                        uid: img,
                        name: img,
                        status: 'done',
                        url: `/uploads/${img}`,
                        uploaded: true
                    }
                })
                form.setFieldsValue({
                    title: data.news.title,
                    description: data.news.description,
                    images,
                    short: data.news.short
                })
            })
            .catch(err => {
                console.error(err)
                message.error('Не удалось загрузить новость')
            })
    }

    const onSubmitForm = values => {
        const images = values.images.map(img => (img.originFileObj ? img.originFileObj : img))
        if (images.length > 10) {
            message.warning('Вы не можете загрузить больше 10 фото')
        } else {
            const formData = new FormData()
            formData.append('title', values.title)
            formData.append('description', values.description)
            formData.append('short', values.short)
            deletedImages.forEach(img => formData.append('deletedImages', img))
            images.forEach(img => formData.append('images', img))
            setEditing(true)
            api()
                .put(`/news/${id}`, formData)
                .then(() => {
                    setEditing(false)
                    message.success('Новость изменена')
                    history.push(`/news/${id}`)
                })
                .catch(err => {
                    setEditing(false)
                    console.error(err)
                    message.error('Не удалось изменить новость')
                })
        }
    }

    const normFile = e => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    useEffect(() => {
        getSingleNews()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Top title="Изменить новость" />
            <Form form={form} onFinish={onSubmitForm} layout="vertical" name="add-news">
                <Form.Item label="Заголовок" name="title" rules={[rules.required]}>
                    <Input disabled={loading} />
                </Form.Item>
                <Form.Item label="Краткое описание" name="short" rules={[rules.required]}>
                    <Input.TextArea rows={3} />
                </Form.Item>
                <Form.Item
                    label="Текст"
                    name="description"
                    valuePropName="data"
                    getValueFromEvent={(_, editor) => {
                        const data = editor.getData()
                        return data
                    }}
                >
                    <CKEditor
                        editor={ClassicEditor}
                        config={{
                            toolbar: editorToolbar,
                            language: 'ru'
                        }}
                        language="ru"
                        disabled={loading}
                    />
                </Form.Item>
                <Form.Item
                    name="images"
                    label="Фотографии"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    style={{ flexFlow: 'column' }}
                >
                    <Upload
                        disabled={loading}
                        accept="image/*"
                        multiple
                        beforeUpload={() => false}
                        listType="picture-card"
                        onRemove={removed => {
                            if (removed.uploaded) {
                                setDeletedImages(prev => [...prev, removed.name])
                            }
                        }}
                    >
                        <UploadButton />
                    </Upload>
                </Form.Item>
                <Button disabled={loading} loading={editing} type="primary" htmlType="submit">
                    Изменить
                </Button>
            </Form>
        </>
    )
}

const Form = styled(AntForm)`
    max-width: 600px;
    margin-top: 7px;

    .ck-editor {
        border-radius: 2px;
        border: 1px solid #d9d9d9;
        transition: 0.3s border-color;

        &:hover {
            border-color: #40a9ff;
        }
    }

    .ck.ck-toolbar {
        border: none;
        border-bottom: 1px solid #d9d9d9 !important;
    }

    .ck-focused {
        border: none !important;
        box-shadow: none !important;
    }

    .ck.ck-editor__editable_inline {
        border: none;
    }

    .ant-form-item-has-error {
        .ck-editor {
            border: 1px solid #ff4d4f;
        }
    }
`

const UploadButtonView = styled.div`
    .text {
        margin-top: 8px;
        color: #666;
    }
`

export default NewsEdit
